<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: "App",
};
</script>

<style>
img,
body,
div,
span {
	margin: 0;
	padding: 0;
}
i,
em {
	font-style: normal;
}
</style>
