import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "home",
		meta: { title: "滴答倒数日" },
		component: () => import("@/views/home.vue"),
	},
	{
		path: "/weights-help",
		name: "weights-help",
		meta: { title: "操作帮助" },
		component: () => import("@/views/weights-help.vue"),
	},
	{
		path: "/privacy",
		name: "privacy",
		meta: { title: "隐私政策" },
		component: () => import("@/views/privacy.vue"),
	},
	{
		path: "/agreement",
		name: "agreement",
		meta: { title: "用户协议" },
		component: () => import("@/views/agreement.vue"),
	},
	{
		path: "/history",
		name: "history",
		meta: { title: "历史上的今天" },
		component: () => import("@/views/history.vue"),
	},
];

// 创建路由
const router = createRouter({
	// history模式：createWebHistory
	history: createWebHistory("/"),
	routes,
});

router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title;
	next();
});
export default router;
